export default [
  {
    title: 'วัสดุ',
    icon: 'PackageIcon',
    admin: 'แอดมิน',
    user: 'ผู้ใช้ทั่วไป',
    supplies_officer: 'เจ้าหน้าที่พัสดุ',
    children: [
      {
        title: 'รับเข้าวัสดุ',
        route: 'inpuwassadu',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
        // ผู้ใช้ทั่วไป: 'ผู้ใช้ทั่วไป',
      },
      {
        title: 'วัสดุคงคลัง',
        route: 'rowwasadu',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'เบิกวัสดุ',
        route: 'outputwassadu',
        admin: 'แอดมิน',
        user: 'ผู้ใช้ทั่วไป',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },

      {
        title: 'ค้นหาวัสดุ',
        route: 'seawassadu',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      
      // {
      //   title: 'รายงานกลุ่มงาน',
      //   route: 'report_wassadu_workgroup',
      //   admin: 'แอดมิน',
      //   supplies_officer: 'เจ้าหน้าที่พัสดุ',
      // },
    ],
  },
  {
    title: 'ทรัพย์สิน',
    icon: 'InboxIcon',
    admin: 'แอดมิน',
    user: 'ผู้ใช้ทั่วไป',
    supplies_officer: 'เจ้าหน้าที่พัสดุ',
    children: [
      {
        title: 'รับเข้าทรัพย์สิน',
        route: 'inputkuruplun',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ทะเบียนทรัพย์สิน',
        route: 'waitingkuruplun',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      // {
      //   title: 'ทรัพย์สินคงคลัง',
      //   route: 'inventory',
      // },
      {
        title: 'เบิกทรัพย์สิน',
        route: 'outputkuruplun',
        admin: 'แอดมิน',
        user: 'ผู้ใช้ทั่วไป',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ส่งคืนทรัพย์สิน',
        route: 'returnkuruplun',
        admin: 'แอดมิน',
        user: 'ผู้ใช้ทั่วไป',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ยืม/คืน ทรัพย์สิน',
        route: 'borrowkuruplun',
        admin: 'แอดมิน',
        user: 'ผู้ใช้ทั่วไป',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
     

      {
        title: 'ค้นหาทรัพย์สิน',
        route: 'seakuruplun',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      // {
      //   title: 'รายงานกลุ่มทรัพย์สิน',
      //   route: 'report_kuruplun',
      //   admin: 'แอดมิน',
      //   supplies_officer: 'เจ้าหน้าที่พัสดุ',
      // },
      // {
      //   title: 'รายงานกลุ่มงาน',
      //   route: 'report_kuruplun_workgroup',
      //   admin: 'แอดมิน',
      //   supplies_officer: 'เจ้าหน้าที่พัสดุ',
      // },
    ],
  },
  {
    title: 'ตรวจสอบทรัพย์สิน',
    icon: 'BriefcaseIcon',
    admin: 'แอดมิน',
    inspector: 'ผู้ตรวจสอบ',
    supplies_officer: 'เจ้าหน้าที่พัสดุ',
    user: 'ผู้ใช้ทั่วไป',
    children: [
      // {
      //   title: 'ประมวลผลค่าเสื่อมประจำปี',
      //   route: 'deterioration',
      // },
      {
        title: 'ตรวจนับทรัพย์สิน',
        route: 'counting',
        admin: 'แอดมิน',
        inspector: 'ผู้ตรวจสอบ',     
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
        
      },
      {
        title: 'ตรวจนับทรัพย์สิน สทศ',
        route: 'counting_neits',
        admin: 'แอดมิน',
        inspector: 'ผู้ตรวจสอบ',     
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
        
      },
      {
        title: 'ตรวจสอบประจำปี',
        route: 'annualcheck',
        admin: 'แอดมิน',
        inspector: 'ผู้ตรวจสอบ',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ตรวจสอบประจำปี สทศ',
        route: 'annualcheck_neits',
        admin: 'แอดมิน',
        inspector: 'ผู้ตรวจสอบ',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'จำหน่ายทรัพย์สิน',
        route: 'selling',
        admin: 'แอดมิน',
        inspector: 'ผู้ตรวจสอบ',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ประวัติการแจ้งซ่อม',
        route: 'rehearsal',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
    ],
  },
  {
    title: 'ตั้งค่าข้อมูล',
    icon: 'DatabaseIcon',
    admin: 'แอดมิน',
    supplies_officer: 'เจ้าหน้าที่พัสดุ',
    children: [
      {
        title: 'กลุ่มวัสดุ',
        route: 'material-group',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'กลุ่มทรัพย์สิน',
        route: 'equipment',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ทะเบียนคุมวัสดุ',
        route: 'majorwasadu',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ทะเบียนคุมทรัพย์สิน',
        route: 'majorkuruplun',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'กลุ่มงาน',
        route: 'workgroup',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'หน่วยนับ',
        route: 'unittype',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'วิธีการจัดหา',
        route: 'procurement_method',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ประเภทเงิน',
        route: 'money_type',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ประเภทหลักฐาน',
        route: 'proof_type',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ผู้ขาย',
        route: 'seller',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ผู้ใช้งาน',
        route: 'user',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ตั้งค่าฟอนต์',
        route: 'font',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ตั้งค่าตำแหน่ง',
        route: 'position',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'Import',
        route: 'import',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
    ],
  },
  {
    title: 'ประวัติการใช้งาน',
    route: 'usageHistory',
    icon: 'ServerIcon',
    admin: 'แอดมิน',
    supplies_officer: 'เจ้าหน้าที่พัสดุ',
  },
  {
    title: 'รายงาน',
    route: '',
    icon: 'FileTextIcon',
    admin: 'แอดมิน',
    supplies_officer: 'เจ้าหน้าที่พัสดุ',
    children: [
      {
        title: 'วัสดุ-กลุ่มวัสดุ',
        route: 'material_group_report',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'วัสดุ-กลุ่มงาน',
        route: 'material_work_group_report',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'วัสดุ-รายตัว',
        route: 'material_group_report_anyone',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ทรัพย์สิน-กลุ่มทรัพย์สิน',
        route: 'asset_group_report',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      {
        title: 'ทรัพย์สิน-กลุ่มงาน',
        route: 'property_group_report',
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
      // {
      //   title: 'ทะเบียนคุมทรัพย์สิน',
      //   route: 'controlregistration',        
      //   admin: 'แอดมิน',
      //   supplies_officer: 'เจ้าหน้าที่พัสดุ',
      // },
      {
        title: 'ทะเบียนคุมทรัพย์สิน',
        route: 'controlregistrationall',       
        admin: 'แอดมิน',
        supplies_officer: 'เจ้าหน้าที่พัสดุ',
      },
    ],
  },
];
// to: { name: 'rowwasadu' }
