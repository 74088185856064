var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canViewVerticalNavMenuLink(_vm.item))?_c('li',{staticClass:"nav-item",class:{
    active: _vm.isActive,
    disabled: _vm.item.disabled,
  }},[(
      _vm.item.admin === _vm.status ||
      _vm.item.user === _vm.status ||
      _vm.item.inspector === _vm.status ||
      _vm.item.supplies_officer === _vm.status
    )?_c('b-link',_vm._b({staticClass:"d-flex align-items-center"},'b-link',_vm.linkProps,false),[_c('feather-icon',{attrs:{"icon":_vm.item.icon || 'CircleIcon'}}),_c('span',{staticClass:"menu-title text-truncate"},[_vm._v(_vm._s(_vm.t(_vm.item.title)))]),(_vm.item.tag)?_c('b-badge',{staticClass:"mr-1 ml-auto",attrs:{"pill":"","variant":_vm.item.tagVariant || 'primary'}},[_vm._v(" "+_vm._s(_vm.item.tag)+" ")]):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }